import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./top-menu.scss";

function TopMenu() {
  return (
    <header className="header header-secondary">
      <Container>
        <div className="header-top">
          <Row>
            <Col xs={12} md={4}>
              <p>Welcome to ITNOA Devs.</p>
            </Col>
            <Col xs={12} md={8} className="d-none d-lg-block">
              <Row>
                <Col xs={4}>
                  <div className="header-top-item">
                    <span className="header-top-icon">
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <p>+1 (972) 737-9612</p>
                  </div>
                </Col>

                <Col xs={4}>
                  <div className="header-top-item">
                    <span className="header-top-icon">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <p>info@itnoadevs.com</p>
                  </div>
                </Col>

                <Col xs={4}>
                  <div className="header-top-item">
                    <span className="header-top-icon">
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </span>
                    <p>15851 Dallas Pkwy #306 Addison, TX 75001, USA</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </header>
  );
}

export default TopMenu;
