import React from "react";
import "./our-partners.scss"

function ListBrands () {
    const brandNames = [
        {
            id: 1,
            title: "PlusCBD",
            imageUrl: process.env.PUBLIC_URL + "./images/plusCBD.png",
            imageUrlResponsive: process.env.PUBLIC_URL + "./images/plusCBD.png"
        },
        {
            id: 2,
            title: "CV Sciences",
            imageUrl: process.env.PUBLIC_URL + "./images/cvsciences.png",
            imageUrlResponsive: process.env.PUBLIC_URL + "./images/cvsciences.png"
        },
        {
            id: 3,
            title: "Diplomat",
            imageUrl: process.env.PUBLIC_URL + "./images/diplomatic.png",
            imageUrlResponsive: process.env.PUBLIC_URL + "./images/diplomatic.png"
        },
        {
            id: 4,
            title: "וג'ייקובס",
            imageUrl: process.env.PUBLIC_URL + "./images/logo-lor.png",
            imageUrlResponsive: process.env.PUBLIC_URL + "./images/logo-lor.png"
        },
        {
            id: 5,
            title: "Jacobs Cofee",
            imageUrl: process.env.PUBLIC_URL + "./images/jacob.png",
            imageUrlResponsive: process.env.PUBLIC_URL + "./images/jacob.png"

        },
        {
            id: 6,
            title: "Syte",
            imageUrl: process.env.PUBLIC_URL + "./images/syte.png",
            imageUrlResponsive: process.env.PUBLIC_URL + "./images/syte.png"

        },
        {
            id: 7,
            title: "Zakeke",
            imageUrl: process.env.PUBLIC_URL + "./images/zakeke1.png",
            imageUrlResponsive: process.env.PUBLIC_URL + "./images/zakeke1.png"

        },
        {
            id: 8,
            title: "Tnuva",
            imageUrl: process.env.PUBLIC_URL + "./images/tnuva.png",
            imageUrlResponsive: process.env.PUBLIC_URL + "./images/tnuva.png"

        },
        {
            id: 9,
            title: "Weatehrman Umbrella",
            imageUrl: process.env.PUBLIC_URL + "./images/weatherman_logo.png",
            imageUrlResponsive: process.env.PUBLIC_URL + "./images/weatherman_logo.png"

        },
        {
            id: 10,
            title: "Pakke Post",
            imageUrl: process.env.PUBLIC_URL + "./images/pakkepost.png",
            imageUrlResponsive: process.env.PUBLIC_URL + "./images/pakkepost.png"

        },
        {
            id: 11,
            title: "Awin Affiliate",
            imageUrl: process.env.PUBLIC_URL + "./images/AWIN_logo.png",
            imageUrlResponsive: process.env.PUBLIC_URL + "./images/AWIN_logo.png"

        }
    ];

    const brandMap = brandNames.map(item => {
        return (
            <div key={item.id} className="col-lg-3 col-md-3 col-sm-3 col-6 brand-card">
                <div className="logo">
                    <picture>
                        <source media="(min-width: 768px)" srcSet={item.imageUrl} />
                        <source media="(min-width: 320px)" srcSet={item.imageUrlResponsive} />
                        <img src={item.imageUrl} alt="project-item" />
                    </picture>
                </div>
            </div>
        )
    });

    return (
        <div id="partner" className="container brandparent">
            <h1 className="title">Our Partners</h1>
            <p className="sub-title">our best clients to deal with are here</p>
            <div className="row brandinner">
                {brandMap}
            </div>
        </div>
    )
}

export default ListBrands;