import React from "react";
import { Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuoteRight,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import "./testimonials.scss";

function Testimonials() {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
  };

  return (
    <div className="review-section" id="testimonials">
      <Container>
        <div className="review-content">
          <h3 className="title">Clients Feedback</h3>
          <p className="desc">
            We left some of the biggest agencies to do it better. Don't take our
            word for it, see what people say about us.
          </p>
        </div>
        <div className="review-slider">
          <Slider {...settings}>
            <div className="review-item">
              <i className="review-icon">
                <FontAwesomeIcon icon={faQuoteRight} />
              </i>
              <div className="client-image">
                <img
                  src={process.env.PUBLIC_URL + "/images/stig.jpeg"}
                  alt="client-thumbnail"
                />
              </div>

              <div className="client-detail">
                <h3 className="client-name">Stig Andengaard</h3>
                <ul className="nav client-rating">
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                </ul>
                <p>
                Great company and guy to work with. He quick gets what the customer needs and deliver on time and high quality
                </p>
                <span className="client-rank">Partner/Founder of Pakkepost AS & Payee AS</span>
              </div>
            </div>

            <div className="review-item">
              <i className="review-icon">
                <FontAwesomeIcon icon={faQuoteRight} />
              </i>
              <div className="client-image">
                <img
                  src={process.env.PUBLIC_URL + "/images/rick.png"}
                  alt="client-thumbnail"
                />
              </div>

              <div className="client-detail">
                <h3 className="client-name">Rick Reichmuth</h3>
                <ul className="nav client-rating">
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                </ul>
                <p>
                Nouman is awesome to work with. He's knowledgable and hits deadlines. He understands what needs to be done and helps our team understand some development plans that have been complicated for us to achieve. He's also an honest, easy going guy who is a pleasure to work with. I'd recommend him to anyone who has SFCC development needs.
                </p>
                <span className="client-rank">CEO of Weatehrman Umbrella</span>
              </div>
            </div>

            <div className="review-item">
              <i className="review-icon">
                <FontAwesomeIcon icon={faQuoteRight} />
              </i>
              <div className="client-image">
                <img
                  src={process.env.PUBLIC_URL + "/images/person2.png"}
                  alt="client-thumbnail"
                />
              </div>

              <div className="client-detail">
                <h3 className="client-name">ido david</h3>
                <ul className="nav client-rating">
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                </ul>
                <p>
                Nouman and the team did a great job fixing our SFCC cartridge in order to get certification.
                Great communication by on-going clear updates, great time and effort estimation !
                Thank you !
                </p>
                <span className="client-rank">R&D Senior Director at Syte.ai</span>
              </div>
            </div>
            
            <div className="review-item">
              <i className="review-icon">
                <FontAwesomeIcon icon={faQuoteRight} />
              </i>
              <div className="client-image">
                <img
                  src={process.env.PUBLIC_URL + "/images/client.jpeg"}
                  alt="client-thumbnail"
                />
              </div>

              <div className="client-detail">
                <h3 className="client-name">Brian Haghighi</h3>
                <ul className="nav client-rating">
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                </ul>
                <p>
                Nouman is one of the most exceptional technical leads I have worked with in the last 15 years of software development.
                 He has an expert-level grasp of SFCC and understands how all components work together in the SFRA framework. Additionally, he approaches all work in a level-headed manner that manages expectations and delivers exceptional products on time. Wish we engaged Nouman earlier!.
                </p>
                <span className="client-rank">Director of Enterprise Technology Solutions at CV Sciences</span>
              </div>
            </div>

            <div className="review-item">
              <i className="review-icon">
                <FontAwesomeIcon icon={faQuoteRight} />
              </i>
              <div className="client-image">
                <img
                  src={process.env.PUBLIC_URL + "/images/bry.jpeg"}
                  alt="client-thumbnail"
                />
              </div>

              <div className="client-detail">
                <h3 className="client-name">Omer Lavi</h3>
                <ul className="nav client-rating">
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                </ul> 
                <p>
                Nouman and Team understand the SFCC architecture forwards and backward. 
                Team approaches all solution designs with a mastery of best practices 
                while considering all of the options present in SFCC architecture.
                </p>
                <span className="client-rank">Project Manager at  Fisha</span>
              </div>
            </div>

            <div className="review-item">
              <i className="review-icon">
                <FontAwesomeIcon icon={faQuoteRight} />
              </i>
              <div className="client-image">
                <img
                  src={process.env.PUBLIC_URL + "/images/person.png"}
                  alt="client-thumbnail"
                />
              </div>

              <div className="client-detail">
                <h3 className="client-name">Carlo Dalessandro</h3>
                <ul className="nav client-rating">
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} />
                  </li>
                </ul>
                <p>
                As COO at Zakeke, an Italian SaaS with clients all over the world, 
                I've been working with  ITNOA Devs for almost 1 year now. They know the SFCC environment very well and are crucial
                to deliver solutions to our clients that use SFCC as ecommerce platform! Keep it up guys!
                </p>
                <span className="client-rank">Product Manager at zkeke</span>
              </div>
            </div>

          </Slider>
        </div>
      </Container>
    </div>
  );
}

export default Testimonials;
