import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./request-quote.scss";

function RequestQuote() {
  const [value, setValue] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });
  const [success, setSuccess] = useState();
  const [error, setError] = useState([]);

  const handleChange = (evt) => {
    setValue({
      ...value,
      [evt.target.name]: evt.target.value,
    });
  };

  const submitForm = (evt) => {
    evt.preventDefault();

    axios
      .post("/backend/send-email", value)
      .then((res) => {
        setError([]);
        setSuccess(res.data.success);
        setError(res.data.errors);
        setValue({
          name: "",
          phone: "",
          email: "",
          subject: "",
          message: "",
        });
      })
      .catch((err) => {
        setError([]);
        setError(err.response.data.errors);
        if (err.response.status !== 422 || err.response.status !== 200) {
          setError(
            [{
              'status': 404,
              'message': 'something went wrong.'
            }]
          );
        }
        setSuccess(null);
      });
  };

  const getErrorHTML = (error) => {
    if (error) {
      var errorContainer = document.createElement("p");
      errorContainer.className = "error-msg";
      errorContainer.innerHTML = error;
      return errorContainer;
    }
  };

  const getSuccessHTML = (success) => {
    if (success) {
      var successContainer = document.createElement("p");
      successContainer.className = "success-msg";
      successContainer.innerHTML = success;
      return successContainer;
    }
  };

  const getErrorAlert = (error) => {
    if (error) {
      var successContainer = document.createElement("p");
      successContainer.className = "error-alert";
      successContainer.innerHTML = error;
      return successContainer;
    }
  };

  const clearErrors = () => {
    var errorFields = document.getElementsByClassName("error-msg");
    for (var i = 0; i < errorFields.length; i++) {
      let inputSiblings = errorFields[i].previousElementSibling;
      inputSiblings.classList.remove("field-error");
      errorFields[i].remove();
    }
  };

  if (error && (error.length > 0)) {
    clearErrors();
    if (error[0]?.status == 404) {
      document.querySelector(".alert-box").innerHTML = "";
      document.querySelector(".alert-box").appendChild(getErrorAlert(error[0].message));
      setTimeout(() => {
        document.querySelector(".alert-box").innerHTML = "";
      }, 5000);
      setError([]);
    } else {
      error.forEach((err) => {
        document.querySelectorAll(`[name="${err.field}"]`).forEach((el) => {
          el.parentNode.insertBefore(getErrorHTML(err.message), el.nextSibling);
          el.classList.add("field-error");
        });
      });
    }
  } else {
    clearErrors();
    if (success) {
      document.querySelector(".alert-box").innerHTML = "";
      document
        .querySelector(".alert-box")
        .appendChild(getSuccessHTML(success));
      setTimeout(() => {
        document.querySelector(".alert-box").innerHTML = "";
      }, 5000);
      setSuccess(null);
    }
  }

  return (
    <div className="quote-section" id="contact">
      <Container>
        <Row className="quote-row no-gutters">
          <Col sm={12} lg={8}>
            <div className="form-column">
              <h3 className="title">Request A Quote</h3>
              <p>
                Reach out below with any inquiries or project ideas. We'd love
                to hear from you.
              </p>
              <div className="alert-box"></div>
              <form
                action="/"
                onSubmit={submitForm}
                method="post"
                className="form"
              >
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={value.name}
                        name="name"
                        onChange={(e) => handleChange(e)}
                        placeholder="Full Name"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={value.phone}
                        name="phone"
                        onChange={(e) => handleChange(e)}
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={value.email}
                        name="email"
                        onChange={(e) => handleChange(e)}
                        placeholder="Email Address"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={value.subject}
                        name="subject"
                        onChange={(e) => handleChange(e)}
                        placeholder="Subject"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        name="message"
                        value={value.message}
                        onChange={(e) => handleChange(e)}
                        placeholder="Your Message"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="row mt-2 align-items-center">
                      <div className="col-12 col-md-6">
                        <div className="checkbox-inline form-group">
                          <input type="checkbox" />
                          <p>Also subscribe us </p>
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group d-flex justify-content-md-end">
                          <button type="submit" className="btn btn-send">
                            Send Data
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Col>

          <Col sm={12} lg={4}>
            <div className="contact-column">
              <h3>Contact Details</h3>
              <p>Get in touch with us</p>

              <div className="contact-item">
                <div className="contact-icon">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <div className="contact-detail">
                  <h4>Phone:</h4>
                  <p>+1 (972) 737-9612</p>
                </div>
              </div>

              <div className="contact-item">
                <div className="contact-icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div className="contact-detail">
                  <h4>Email:</h4>
                  <p>info@itnoadevs.com</p>
                </div>
              </div>

              <div className="contact-item">
                <div className="contact-icon">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </div>
                <div className="contact-detail">
                  <h4>Address:</h4>
                  <p>15851 Dallas Pkwy #306 Addison, TX 75001, USA</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RequestQuote;
