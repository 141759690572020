import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal, faUsers, faTrophy } from "@fortawesome/free-solid-svg-icons";
import "./feature.scss";

function Feature() {
  return (
    <div className="features">
      <Container>
        <div className="expr-row">
          <Row>
            <Col sm={12} lg={4}>
              <div className="expr-item row">
                <div className="col-2">
                  <FontAwesomeIcon icon={faMedal} />
                </div>
                <div className="col-10">
                  <h3>7+ Years of Experience</h3>
                  <p>We are pioneer in salesforce. </p>
                </div>
              </div>
            </Col>

            <Col sm={12} lg={4}>
              <div className="expr-item row">
                <div className="col-2">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <div className="col-10">
                  <h3>50+ Developers</h3>
                  <p>Highly skilled certified resources</p>
                </div>
              </div>
            </Col>

            <Col sm={12} lg={4}>
              <div className="expr-item row">
                <div className="col-2">
                  <FontAwesomeIcon icon={faTrophy} />
                </div>
                <div className="col-10">
                  <h3>25+ Awards</h3>
                  <p>Tremendous achievements</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Feature;
