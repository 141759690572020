import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLayerGroup,
  faPuzzlePiece,
  faMicroscope,
  faCloud,
  faCode,
  faWindowRestore
} from "@fortawesome/free-solid-svg-icons";
import { faShopify } from '@fortawesome/free-brands-svg-icons';
import "./services.scss";

const servicesImage = {
  backgroundImage:
    "url('../images/itnoa-service-bg.webp'), url('../images/itnoa-service-bg.jpg')",
};

function Services() {
  return (
    <div className="service" id="services" style={servicesImage}>
      <Container className="service-container">
        <div className="service-upper">
          <h3>Our Services</h3>
          <p>
            We provide the best services which helps clients & business to boost
            up their sales & attract maximum customers
          </p>
        </div>

        <Row className="service-row">
          <Col xs={12} md={6} lg={4}>
            <div className="service-item">
              <div className="service-icon">
              <FontAwesomeIcon icon={faCode} />
              </div>
              <div className="service-body">
                <h3>Website Development</h3>
                <p>
                Crafting robust, user-friendly websites to enhance online presence and drive transformative growth. Engaging customers through seamless design and functionality
                </p>
              </div>
            </div>
          </Col>

          <Col xs={12} md={6} lg={4}>
            <div className="service-item">
              <div className="service-icon">
              <FontAwesomeIcon icon={faWindowRestore} />
              </div>
              <div className="service-body">
                <h3>Application Development</h3>
                <p>
                We specialize in crafting cutting-edge applications, and harnessing advanced technologies to enhance productivity, scalability, and user experience. Our solutions ensure seamless interaction, driving efficiency and innovation
                </p>
              </div>
            </div>
          </Col>

          <Col xs={12} md={6} lg={4}>
            <div className="service-item">
              <div className="service-icon">
                <FontAwesomeIcon icon={faPuzzlePiece} />
              </div>
              <div className="service-body">
                <h3>Custom Software Development</h3>
                <p>
                Our expert engineers craft bespoke software solutions, optimizing operations for peak efficiency and propelling business expansion
                </p>
              </div>
            </div>
          </Col>

          <Col xs={12} md={6} lg={4}>
            <div className="service-item">
              <div className="service-icon">
              <FontAwesomeIcon icon={faLayerGroup} />
              </div>
              <div className="service-body">
                <h3>Plugin/Module Development</h3>
                <p>
                  We provide our top notch services to develope salesforce link
                  cartridges for our valuable customers. Our development team
                  has developed couple of link cartridges for payment, reviews
                  etc. for world's top brand
                </p>
              </div>
            </div>
          </Col>

          <Col xs={12} md={6} lg={4}>
            <div className="service-item">
              <div className="service-icon">
                <FontAwesomeIcon icon={faCloud} />
              </div>
              <div className="service-body">
                <h3>Cloud Application Development</h3>
                <p>
                ITNOA Devs specializes in crafting adaptable cloud solutions, prioritizing data security, affordability, and seamless accessibility across diverse platforms
                </p>
              </div>
            </div>
          </Col>

          <Col xs={12} md={6} lg={4}>
            <div className="service-item">
              <div className="service-icon">
              <FontAwesomeIcon icon={faMicroscope} />
              </div>
              <div className="service-body">
                <h3>Software Testing</h3>
                <p>
                  Our experts set the groundwork and purpose-simplified plans to
                  base the enterprise website. Our outstanding team of UI-UX
                  applies the best practices to analyze your business and offer
                  research based on a well-thought strategy
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Services;
