import React from "react";
import { Button, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import "./hero-banner.scss";

const heroImage = {
  backgroundImage: "url(./images/herobanner-one.jpg)",
};

function HeroBanner() {
  // Define a function to handle scrolling to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling behavior
    });
  };

  return (
    <div className="hero-banner" style={heroImage} id="home">
      <div className="hero-banner-body">
        <Container>
          <div className="hero-banner-content">
            <h3 className="hero-banner-title">
              Welcome to <span className="text-lbue">ITNOA Devs</span>
            </h3>
            <p className="hero-banner-desc">
              Itnoa aims to deliver top-notch solutions for businesses of all
              sizes worldwide. With a vision to foster growth for every client,
              we began integrating multi-channel solutions into their
              operations. Our team of highly qualified experts in software
              development works diligently on enhancing your enterprise's online
              presence, empowering you to achieve success
            </p>
          </div>
        </Container>
      </div>

      <div className="hero-banner-scroll">
        <Button className="btn-bottom" onClick={scrollToTop}>
          <FontAwesomeIcon icon={faArrowDown} />
        </Button>
      </div>
    </div>
  );
}

export default HeroBanner;
